/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Flex, useMatchBreakpoints } from '@pancakeswap/uikit'
import { Dropdown, MenuProps } from 'antd'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import MenuIcon from './MenuIcon'

const MENU_HEIGHT = 84

const WrapMenu = styled.div`
  overflow: hidden;
  .nav {
    position: fixed;
    z-index: 20;
    background-color: #03898929;
    .logo {
      max-height: 50px;
    }
    padding: 17px 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    height: 84px;
    ${({ theme }) => theme.mediaQueries.sm} {
      height: 84px;
    }
    @media (max-width: 1025px) {
      padding: 17px 20px;
      .left-menu {
        gap: 10px;
      }
    }
    .ant-dropdown .ant-dropdown-menu,
    .ant-dropdown-menu-submenu .ant-dropdown-menu {
      background-color: #000000 !important;
    }
  }
  .historyMn {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    z-index: 10;
    padding: 7px 15px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.6);
    gap: 10px;
  }
`

const DropdownCustom = styled(Dropdown)`
  .ant-dropdown-menu {
    background-color: #000000 !important;
  }
`

const Container = styled.div``

const InnerBody = styled.div`
  width: 100%;
  // max-width: 1300px;
  padding: 0 16px;
  margin-top: ${MENU_HEIGHT}px;
  height: calc(100vh - ${MENU_HEIGHT}px);
  background-image: url('/images/background/bg.png');
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  @media only screen and (max-width: 768px) {
    height: calc(100vh);
    padding-top: ${MENU_HEIGHT}px;
    margin-top: 0px;
  }
`
const Footer = styled.div`
  padding: 0px 10px 40px 10px;
  text-align: center;
  color: #686868;
`

export const StyledButton = styled(Button)`
  width: 148.16px;
  border-radius: 7px;
  color: #fff;
  padding: 2px;
  margin-left: 6px;
  @media only screen and (max-width: 768px) {
    width: 69.53px;
    height: 22.05px;
    opacity: 0px;
  }
`
const StyledConnect = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 37.5px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }
`
const Menu = ({ children }) => {
  const router = useRouter()
  const { isDesktop } = useMatchBreakpoints()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const closeMobileMenu = () => {
    if (!isDesktop) {
      setIsMenuOpen(false)
    }
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          href="/"
          style={{
            color: '#2A2A2A',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          Home
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          href="https://beurl.app/hGHfHsZN"
          target="_blank"
          style={{
            color: '#2A2A2A',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          Document
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <a
          href="https://testnet-scan.tingchain.org/"
          target="_blank"
          style={{
            color: '#2A2A2A',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
          rel="noreferrer"
        >
          Contact
        </a>
      ),
    },
  ]

  return (
    <WrapMenu>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="nav">
        <div style={{ minWidth: 148, minHeight: 50, cursor: 'pointer' }}>
          <Image src="/images/logo/logo.svg" alt="avb" width={236.63} height={64} />
        </div>

        {!isDesktop ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <Dropdown
              className="my-dropdown"
              trigger={['hover']}
              menu={{ items, theme: 'dark', style: { backgroundColor: '#03898929', width: '105px' } }}
              placement="bottomCenter"
            >
              <MenuIcon />
            </Dropdown>
          </div>
        ) : (
          <>
            {' '}
            <Flex className="left-menu" alignItems="center" style={{ gap: 54 }}>
              <a href="/" style={{ color: '#2A2A2A', cursor: 'pointer', fontSize: 22 }} rel="noreferrer">
                Home
              </a>
              <a
                href="https://beurl.app/hGHfHsZN"
                target="_blank"
                style={{ color: '#2A2A2A', cursor: 'pointer', fontSize: 22 }}
                rel="noreferrer"
              >
                Document
              </a>

              <a
                href="https://testnet-scan.tingchain.org/"
                target="_blank"
                style={{ color: '#2A2A2A', cursor: 'pointer', fontSize: 22 }}
                rel="noreferrer"
              >
                Contact
              </a>
            </Flex>
          </>
        )}
      </div>
      <InnerBody>{children}</InnerBody>
      {/* <Footer>Copyright © 2024 Udefi. All rights reserved</Footer> */}
    </WrapMenu>
  )
}

export default Menu
